@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');

html {
  margin: 0;
  color: #484848;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  margin-left: auto;
  margin-right: auto;
}

table th {
  word-break: break-all;
}
